import { CFOP } from "../../interface/cfop";

export const CFOP_list: CFOP[] = [
    {
        "code": "1101",
        "message": "Compra p/ industrialização ou produção rural",
    },
    {
        "code": "1102",
        "message": "Compra p/ comercialização",
    },
    {
        "code": "1111",
        "message": "Compra p/ industrialização de mercadoria recebida anteriormente em consignação industrial",
    },
    {
        "code": "1113",
        "message": "Compra p/ comercialização, de mercadoria recebida anteriormente em consignação mercantil",
    },
    {
        "code": "1116",
        "message": "Compra p/ industrialização ou produção rural originada de encomenda p/ recebimento futuro",
    },
    {
        "code": "1117",
        "message": "Compra p/ comercialização originada de encomenda p/ recebimento futuro",
    },
    {
        "code": "1118",
        "message": "Compra de mercadoria p/ comercialização pelo adquirente originário, entregue pelo vendedor remetente ao destinatário, em venda à ordem.",
    },
    {
        "code": "1120",
        "message": "Compra p/ industrialização, em venda à ordem, já recebida do vendedor remetente",
    },
    {
        "code": "1121",
        "message": "Compra p/ comercialização, em venda à ordem, já recebida do vendedor remetente",
    },
    {
        "code": "1122",
        "message": "Compra p/ industrialização em que a mercadoria foi remetida pelo fornecedor ao industrializador sem transitar pelo estabelecimento adquirente",
    },
    {
        "code": "1124",
        "message": "Industrialização efetuada por outra empresa",
    },
    {
        "code": "1125",
        "message": "Industrialização efetuada por outra empresa quando a mercadoria remetida p/ utilização no processo de industrialização não transitou pelo estabelecimento adquirente da mercadoria",
    },
    {
        "code": "1126",
        "message": "Compra p/ utilização na prestação de serviço sujeita ao ICMS",
    },
    {
        "code": "1128",
        "message": "Compra p/ utilização na prestação de serviço sujeita ao ISSQN",
    },
    {
        "code": "1151",
        "message": "Transferência p/ industrialização ou produção rural",
    },
    {
        "code": "1152",
        "message": "Transferência p/ comercialização",
    },
    {
        "code": "1153",
        "message": "Transferência de energia elétrica p/ distribuição",
    },
    {
        "code": "1154",
        "message": "Transferência p/ utilização na prestação de serviço",
    },
    {
        "code": "1201",
        "message": "Devolução de venda de produção do estabelecimento ",
        "isDevolution": true
    },
    {
        "code": "1202",
        "message": "Devolução de venda de mercadoria adquirida ou recebida de terceiros",
        "isDevolution": true
    },
    {
        "code": "1203",
        "message": "Devolução de venda de produção do estabelecimento, destinada à ZFM ou ALC",
        "isDevolution": true
    },
    {
        "code": "1204",
        "message": "Devolução de venda de mercadoria adquirida ou recebida de terceiros, destinada à ZFM ou ALC",
        "isDevolution": true
    },
    {
        "code": "1205",
        "message": "Anulação de valor relativo à prestação de serviço de comunicação",
    },
    {
        "code": "1206",
        "message": "Anulação de valor relativo à prestação de serviço de transporte",
    },
    {
        "code": "1207",
        "message": "Anulação de valor relativo à venda de energia elétrica",
    },
    {
        "code": "1208",
        "message": "Devolução de produção do estabelecimento, remetida em transferência",
        "isDevolution": true
    },
    {
        "code": "1209",
        "message": "Devolução de mercadoria adquirida ou recebida de terceiros, remetida em transferência",
        "isDevolution": true
    },
    {
        "code": "1212",
        "message": "Devolução de venda no mercado interno de mercadoria industrializada e insumo importado sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)",
        "isDevolution": true
    },
    {
        "code": "1251",
        "message": "Compra de energia elétrica p/ distribuição ou comercialização",
    },
    {
        "code": "1252",
        "message": "Compra de energia elétrica por estabelecimento industrial",
    },
    {
        "code": "1253",
        "message": "Compra de energia elétrica por estabelecimento comercial",
    },
    {
        "code": "1254",
        "message": "Compra de energia elétrica por estabelecimento prestador de serviço de transporte",
    },
    {
        "code": "1255",
        "message": "Compra de energia elétrica por estabelecimento prestador de serviço de comunicação",
    },
    {
        "code": "1256",
        "message": "Compra de energia elétrica por estabelecimento de produtor rural",
    },
    {
        "code": "1257",
        "message": "Compra de energia elétrica p/ consumo por demanda contratada",
    },
    {
        "code": "1301",
        "message": "Aquisição de serviço de comunicação p/ execução de serviço da mesma natureza",
    },
    {
        "code": "1302",
        "message": "Aquisição de serviço de comunicação por estabelecimento industrial",
    },
    {
        "code": "1303",
        "message": "Aquisição de serviço de comunicação por estabelecimento comercial",
    },
    {
        "code": "1304",
        "message": "Aquisição de serviço de comunicação por estabelecimento de prestador de serviço de transporte",
    },
    {
        "code": "1305",
        "message": "Aquisição de serviço de comunicação por estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "1306",
        "message": "Aquisição de serviço de comunicação por estabelecimento de produtor rural",
    },
    {
        "code": "1351",
        "message": "Aquisição de serviço de transporte p/ execução de serviço da mesma natureza",
    },
    {
        "code": "1352",
        "message": "Aquisição de serviço de transporte por estabelecimento industrial",
    },
    {
        "code": "1353",
        "message": "Aquisição de serviço de transporte por estabelecimento comercial",
    },
    {
        "code": "1354",
        "message": "Aquisição de serviço de transporte por estabelecimento de prestador de serviço de comunicação",
    },
    {
        "code": "1355",
        "message": "Aquisição de serviço de transporte por estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "1356",
        "message": "Aquisição de serviço de transporte por estabelecimento de produtor rural",
    },
    {
        "code": "1360",
        "message": "Aquisição de serviço de transporte por contribuinte-substituto em relação ao serviço de transporte",
    },
    {
        "code": "1401",
        "message": "Compra p/ industrialização ou produção rural de mercadoria sujeita a ST",
    },
    {
        "code": "1403",
        "message": "Compra p/ comercialização em operação com mercadoria sujeita a ST",
    },
    {
        "code": "1406",
        "message": "Compra de bem p/ o ativo imobilizado cuja mercadoria está sujeita a ST",
    },
    {
        "code": "1407",
        "message": "Compra de mercadoria p/ uso ou consumo cuja mercadoria está sujeita a ST",
    },
    {
        "code": "1408",
        "message": "Transferência p/ industrialização ou produção rural de mercadoria sujeita a ST",
    },
    {
        "code": "1409",
        "message": "Transferência p/ comercialização em operação com mercadoria sujeita a ST",
    },
    {
        "code": "1410",
        "message": "Devolução de venda de mercadoria, de produção do estabelecimento, sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "1411",
        "message": "Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "1414",
        "message": "Retorno de mercadoria de produção do estabelecimento, remetida p/ venda fora do estabelecimento, sujeita a ST",
    },
    {
        "code": "1415",
        "message": "Retorno de mercadoria adquirida ou recebida de terceiros, remetida p/ venda fora do estabelecimento em operação com mercadoria sujeita a ST",
    },
    {
        "code": "1451",
        "message": "Retorno de animal do estabelecimento produtor",
    },
    {
        "code": "1452",
        "message": "Retorno de insumo não utilizado na produção",
    },
    {
        "code": "1501",
        "message": "Entrada de mercadoria recebida com fim específico de exportação",
    },
    {
        "code": "1503",
        "message": "Entrada decorrente de devolução de produto, de fabricação do estabelecimento, remetido com fim específico de exportação",
        "isDevolution": true
    },
    {
        "code": "1504",
        "message": "Entrada decorrente de devolução de mercadoria remetida com fim específico de exportação, adquirida ou recebida de terceiros",
        "isDevolution": true
    },
    {
        "code": "1505",
        "message": "Entrada decorrente de devolução simbólica de mercadoria remetida p/ formação de lote de exportação, de produto industrializado ou produzido pelo próprio estabelecimento.",
        "isDevolution": true
    },
    {
        "code": "1506",
        "message": "Entrada decorrente de devolução simbólica de mercadoria, adquirida ou recebida de terceiros, remetida p/ formação de lote de exportação.",
        "isDevolution": true
    },
    {
        "code": "1551",
        "message": "Compra de bem p/ o ativo imobilizado",
    },
    {
        "code": "1552",
        "message": "Transferência de bem do ativo imobilizado",
    },
    {
        "code": "1553",
        "message": "Devolução de venda de bem do ativo imobilizado",
        "isDevolution": true
    },
    {
        "code": "1554",
        "message": "Retorno de bem do ativo imobilizado remetido p/ uso fora do estabelecimento",
    },
    {
        "code": "1555",
        "message": "Entrada de bem do ativo imobilizado de terceiro, remetido p/ uso no estabelecimento",
    },
    {
        "code": "1556",
        "message": "Compra de material p/ uso ou consumo",
    },
    {
        "code": "1557",
        "message": "Transferência de material p/ uso ou consumo",
    },
    {
        "code": "1601",
        "message": "Recebimento, por transferência, de crédito de ICMS",
    },
    {
        "code": "1602",
        "message": "Recebimento, por transferência, de saldo credor do ICMS, de outro estabelecimento da mesma empresa, p/ compensação de saldo devedor do imposto. ",
    },
    {
        "code": "1603",
        "message": "Ressarcimento de ICMS retido por substituição tributária",
    },
    {
        "code": "1604",
        "message": "Lançamento do crédito relativo à compra de bem p/ o ativo imobilizado",
    },
    {
        "code": "1605",
        "message": "Recebimento, por transferência, de saldo devedor do ICMS de outro estabelecimento da mesma empresa",
    },
    {
        "code": "1651",
        "message": "Compra de combustível ou lubrificante p/ industrialização subseqüente",
    },
    {
        "code": "1652",
        "message": "Compra de combustível ou lubrificante p/ comercialização",
    },
    {
        "code": "1653",
        "message": "Compra de combustível ou lubrificante por consumidor ou usuário final",
    },
    {
        "code": "1658",
        "message": "Transferência de combustível ou lubrificante p/ industrialização",
    },
    {
        "code": "1659",
        "message": "Transferência de combustível ou lubrificante p/ comercialização",
    },
    {
        "code": "1660",
        "message": "Devolução de venda de combustível ou lubrificante destinados à industrialização subseqüente",
        "isDevolution": true
    },
    {
        "code": "1661",
        "message": "Devolução de venda de combustível ou lubrificante destinados à comercialização",
        "isDevolution": true
    },
    {
        "code": "1662",
        "message": "Devolução de venda de combustível ou lubrificante destinados a consumidor ou usuário final",
        "isDevolution": true
    },
    {
        "code": "1663",
        "message": "Entrada de combustível ou lubrificante p/ armazenagem",
    },
    {
        "code": "1664",
        "message": "Retorno de combustível ou lubrificante remetidos p/ armazenagem",
    },
    {
        "code": "1901",
        "message": "Entrada p/ industrialização por encomenda",
    },
    {
        "code": "1902",
        "message": "Retorno de mercadoria remetida p/ industrialização por encomenda",
    },
    {
        "code": "1903",
        "message": "Entrada de mercadoria remetida p/ industrialização e não aplicada no referido processo",
    },
    {
        "code": "1904",
        "message": "Retorno de remessa p/ venda fora do estabelecimento",
    },
    {
        "code": "1905",
        "message": "Entrada de mercadoria recebida p/ depósito em depósito fechado ou armazém geral",
    },
    {
        "code": "1906",
        "message": "Retorno de mercadoria remetida p/ depósito fechado ou armazém geral",
    },
    {
        "code": "1907",
        "message": "Retorno simbólico de mercadoria remetida p/ depósito fechado ou armazém geral",
    },
    {
        "code": "1908",
        "message": "Entrada de bem por conta de contrato de comodato",
    },
    {
        "code": "1909",
        "message": "Retorno de bem remetido por conta de contrato de comodato",
    },
    {
        "code": "1910",
        "message": "Entrada de bonificação, doação ou brinde",
    },
    {
        "code": "1911",
        "message": "Entrada de amostra grátis",
    },
    {
        "code": "1912",
        "message": "Entrada de mercadoria ou bem recebido p/ demonstração",
    },
    {
        "code": "1913",
        "message": "Retorno de mercadoria ou bem remetido p/ demonstração",
    },
    {
        "code": "1914",
        "message": "Retorno de mercadoria ou bem remetido p/ exposição ou feira",
    },
    {
        "code": "1915",
        "message": "Entrada de mercadoria ou bem recebido p/ conserto ou reparo",
    },
    {
        "code": "1916",
        "message": "Retorno de mercadoria ou bem remetido p/ conserto ou reparo",
    },
    {
        "code": "1917",
        "message": "Entrada de mercadoria recebida em consignação mercantil ou industrial",
    },
    {
        "code": "1918",
        "message": "Devolução de mercadoria remetida em consignação mercantil ou industrial",
        "isDevolution": true
    },
    {
        "code": "1919",
        "message": "Devolução simbólica de mercadoria vendida ou utilizada em processo industrial, remetida anteriormente em consignação mercantil ou industrial",
        "isDevolution": true
    },
    {
        "code": "1920",
        "message": "Entrada de vasilhame ou sacaria",
    },
    {
        "code": "1921",
        "message": "Retorno de vasilhame ou sacaria",
    },
    {
        "code": "1922",
        "message": "Lançamento efetuado a título de simples faturamento decorrente de compra p/ recebimento futuro",
    },
    {
        "code": "1923",
        "message": "Entrada de mercadoria recebida do vendedor remetente, em venda à ordem",
    },
    {
        "code": "1924",
        "message": "Entrada p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "1925",
        "message": "Retorno de mercadoria remetida p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "1926",
        "message": "Lançamento efetuado a título de reclassificação de mercadoria decorrente de formação de kit ou de sua desagregação",
    },
    {
        "code": "1931",
        "message": "Lançamento efetuado pelo tomador do serviço de transporte, quando a responsabilidade de retenção do imposto for atribuída ao remetente ou alienante da mercadoria, pelo serviço de transporte realizado por transportador autônomo ou por transportador não-inscrito na UF onde se tenha iniciado o serviço.",
    },
    {
        "code": "1932",
        "message": "Aquisição de serviço de transporte iniciado em UF diversa daquela onde esteja inscrito o prestador",
    },
    {
        "code": "1933",
        "message": "Aquisição de serviço tributado pelo Imposto sobre Serviços de Qualquer Natureza",
    },
    {
        "code": "1934",
        "message": "Entrada simbólica de mercadoria recebida p/ depósito fechado ou armazém geral",
    },
    {
        "code": "1949",
        "message": "Outra entrada de mercadoria ou prestação de serviço não especificada",
    },
    {
        "code": "2101",
        "message": "Compra p/ industrialização ou produção rural",
    },
    {
        "code": "2102",
        "message": "Compra p/ comercialização",
    },
    {
        "code": "2111",
        "message": "Compra p/ industrialização de mercadoria recebida anteriormente em consignação industrial",
    },
    {
        "code": "2113",
        "message": "Compra p/ comercialização, de mercadoria recebida anteriormente em consignação mercantil",
    },
    {
        "code": "2116",
        "message": "Compra p/ industrialização ou produção rural originada de encomenda p/ recebimento futuro",
    },
    {
        "code": "2117",
        "message": "Compra p/ comercialização originada de encomenda p/ recebimento futuro",
    },
    {
        "code": "2118",
        "message": "Compra de mercadoria p/ comercialização pelo adquirente originário, entregue pelo vendedor remetente ao destinatário, em venda à ordem",
    },
    {
        "code": "2120",
        "message": "Compra p/ industrialização, em venda à ordem, já recebida do vendedor remetente",
    },
    {
        "code": "2121",
        "message": "Compra p/ comercialização, em venda à ordem, já recebida do vendedor remetente",
    },
    {
        "code": "2122",
        "message": "Compra p/ industrialização em que a mercadoria foi remetida pelo fornecedor ao industrializador sem transitar pelo estabelecimento adquirente",
    },
    {
        "code": "2124",
        "message": "Industrialização efetuada por outra empresa",
    },
    {
        "code": "2125",
        "message": "Industrialização efetuada por outra empresa quando a mercadoria remetida p/ utilização no processo de industrialização não transitou pelo estabelecimento adquirente da mercadoria",
    },
    {
        "code": "2126",
        "message": "Compra p/ utilização na prestação de serviço sujeita ao ICMS",
    },
    {
        "code": "2128",
        "message": "Compra p/ utilização na prestação de serviço sujeita ao ISSQN",
    },
    {
        "code": "2151",
        "message": "Transferência p/ industrialização ou produção rural",
    },
    {
        "code": "2152",
        "message": "Transferência p/ comercialização",
    },
    {
        "code": "2153",
        "message": "Transferência de energia elétrica p/ distribuição",
    },
    {
        "code": "2154",
        "message": "Transferência p/ utilização na prestação de serviço",
    },
    {
        "code": "2201",
        "message": "Devolução de venda de produção do estabelecimento",
        "isDevolution": true
    },
    {
        "code": "2202",
        "message": "Devolução de venda de mercadoria adquirida ou recebida de terceiros",
        "isDevolution": true
    },
    {
        "code": "2203",
        "message": "Devolução de venda de produção do estabelecimento destinada à ZFM ou ALC",
        "isDevolution": true
    },
    {
        "code": "2204",
        "message": "Devolução de venda de mercadoria adquirida ou recebida de terceiros, destinada à ZFM ou ALC",
        "isDevolution": true
    },
    {
        "code": "2205",
        "message": "Anulação de valor relativo à prestação de serviço de comunicação",
    },
    {
        "code": "2206",
        "message": "Anulação de valor relativo à prestação de serviço de transporte",
    },
    {
        "code": "2207",
        "message": "Anulação de valor relativo à venda de energia elétrica",
    },
    {
        "code": "2208",
        "message": "Devolução de produção do estabelecimento, remetida em transferência.",
        "isDevolution": true
    },
    {
        "code": "2209",
        "message": "Devolução de mercadoria adquirida ou recebida de terceiros e remetida em transferência",
        "isDevolution": true
    },
    {
        "code": "2212",
        "message": "Devolução de venda no mercado interno de mercadoria industrializada e insumo importado sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)",
        "isDevolution": true
    },
    {
        "code": "2251",
        "message": "Compra de energia elétrica p/ distribuição ou comercialização",
    },
    {
        "code": "2252",
        "message": "Compra de energia elétrica por estabelecimento industrial",
    },
    {
        "code": "2253",
        "message": "Compra de energia elétrica por estabelecimento comercial",
    },
    {
        "code": "2254",
        "message": "Compra de energia elétrica por estabelecimento prestador de serviço de transporte",
    },
    {
        "code": "2255",
        "message": "Compra de energia elétrica por estabelecimento prestador de serviço de comunicação",
    },
    {
        "code": "2256",
        "message": "Compra de energia elétrica por estabelecimento de produtor rural",
    },
    {
        "code": "2257",
        "message": "Compra de energia elétrica p/ consumo por demanda contratada",
    },
    {
        "code": "2301",
        "message": "Aquisição de serviço de comunicação p/ execução de serviço da mesma natureza",
    },
    {
        "code": "2302",
        "message": "Aquisição de serviço de comunicação por estabelecimento industrial",
    },
    {
        "code": "2303",
        "message": "Aquisição de serviço de comunicação por estabelecimento comercial",
    },
    {
        "code": "2304",
        "message": "Aquisição de serviço de comunicação por estabelecimento de prestador de serviço de transporte",
    },
    {
        "code": "2305",
        "message": "Aquisição de serviço de comunicação por estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "2306",
        "message": "Aquisição de serviço de comunicação por estabelecimento de produtor rural",
    },
    {
        "code": "2351",
        "message": "Aquisição de serviço de transporte p/ execução de serviço da mesma natureza",
    },
    {
        "code": "2352",
        "message": "Aquisição de serviço de transporte por estabelecimento industrial",
    },
    {
        "code": "2353",
        "message": "Aquisição de serviço de transporte por estabelecimento comercial",
    },
    {
        "code": "2354",
        "message": "Aquisição de serviço de transporte por estabelecimento de prestador de serviço de comunicação",
    },
    {
        "code": "2355",
        "message": "Aquisição de serviço de transporte por estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "2356",
        "message": "Aquisição de serviço de transporte por estabelecimento de produtor rural",
    },
    {
        "code": "2401",
        "message": "Compra p/ industrialização ou produção rural de mercadoria sujeita a ST",
    },
    {
        "code": "2403",
        "message": "Compra p/ comercialização em operação com mercadoria sujeita a ST",
    },
    {
        "code": "2406",
        "message": "Compra de bem p/ o ativo imobilizado cuja mercadoria está sujeita a ST",
    },
    {
        "code": "2407",
        "message": "Compra de mercadoria p/ uso ou consumo cuja mercadoria está sujeita a ST",
    },
    {
        "code": "2408",
        "message": "Transferência p/ industrialização ou produção rural de mercadoria sujeita a ST",
    },
    {
        "code": "2409",
        "message": "Transferência p/ comercialização em operação com mercadoria sujeita a ST",
    },
    {
        "code": "2410",
        "message": "Devolução de venda de produção do estabelecimento, quando o produto sujeito a ST",
        "isDevolution": true
    },
    {
        "code": "2411",
        "message": "Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "2414",
        "message": "Retorno de produção do estabelecimento, remetida p/ venda fora do estabelecimento, quando o produto sujeito a ST",
    },
    {
        "code": "2415",
        "message": "Retorno de mercadoria adquirida ou recebida de terceiros, remetida p/ venda fora do estabelecimento em operação com mercadoria sujeita a ST",
    },
    {
        "code": "2501",
        "message": "Entrada de mercadoria recebida com fim específico de exportação",
    },
    {
        "code": "2503",
        "message": "Entrada decorrente de devolução de produto industrializado pelo estabelecimento, remetido com fim específico de exportação",
        "isDevolution": true
    },
    {
        "code": "2504",
        "message": "Entrada decorrente de devolução de mercadoria remetida com fim específico de exportação, adquirida ou recebida de terceiros",
        "isDevolution": true
    },
    {
        "code": "2505",
        "message": "Entrada decorrente de devolução simbólica de mercadoria remetida p/ formação de lote de exportação, de produto industrializado ou produzido pelo próprio estabelecimento.",
        "isDevolution": true
    },
    {
        "code": "2506",
        "message": "Entrada decorrente de devolução simbólica de mercadoria, adquirida ou recebida de terceiros, remetida p/ formação de lote de exportação.",
        "isDevolution": true
    },
    {
        "code": "2551",
        "message": "Compra de bem p/ o ativo imobilizado",
    },
    {
        "code": "2552",
        "message": "Transferência de bem do ativo imobilizado",
    },
    {
        "code": "2553",
        "message": "Devolução de venda de bem do ativo imobilizado",
        "isDevolution": true
    },
    {
        "code": "2554",
        "message": "Retorno de bem do ativo imobilizado remetido p/ uso fora do estabelecimento",
    },
    {
        "code": "2555",
        "message": "Entrada de bem do ativo imobilizado de terceiro, remetido p/ uso no estabelecimento",
    },
    {
        "code": "2556",
        "message": "Compra de material p/ uso ou consumo",
    },
    {
        "code": "2557",
        "message": "Transferência de material p/ uso ou consumo",
    },
    {
        "code": "2603",
        "message": "Ressarcimento de ICMS retido por substituição tributária",
    },
    {
        "code": "2651",
        "message": "Compra de combustível ou lubrificante p/ industrialização subseqüente",
    },
    {
        "code": "2652",
        "message": "Compra de combustível ou lubrificante p/ comercialização",
    },
    {
        "code": "2652",
        "message": "Compra de combustível ou lubrificante p/ comercialização",
    },
    {
        "code": "2653",
        "message": "Compra de combustível ou lubrificante por consumidor ou usuário final",
    },
    {
        "code": "2658",
        "message": "Transferência de combustível ou lubrificante p/ industrialização",
    },
    {
        "code": "2659",
        "message": "Transferência de combustível ou lubrificante p/ comercialização ",
    },
    {
        "code": "2660",
        "message": "Devolução de venda de combustível ou lubrificante destinados à industrialização subseqüente",
        "isDevolution": true
    },
    {
        "code": "2661",
        "message": "Devolução de venda de combustível ou lubrificante destinados à comercialização",
        "isDevolution": true
    },
    {
        "code": "2662",
        "message": "Devolução de venda de combustível ou lubrificante destinados a consumidor ou usuário final",
        "isDevolution": true
    },
    {
        "code": "2663",
        "message": "Entrada de combustível ou lubrificante p/ armazenagem",
    },
    {
        "code": "2664",
        "message": "Retorno de combustível ou lubrificante remetidos p/ armazenagem",
    },
    {
        "code": "2901",
        "message": "Entrada p/ industrialização por encomenda",
    },
    {
        "code": "2902",
        "message": "Retorno de mercadoria remetida p/ industrialização por encomenda",
    },
    {
        "code": "2903",
        "message": "Entrada de mercadoria remetida p/ industrialização e não aplicada no referido processo",
    },
    {
        "code": "2904",
        "message": "Retorno de remessa p/ venda fora do estabelecimento",
    },
    {
        "code": "2905",
        "message": "Entrada de mercadoria recebida p/ depósito em depósito fechado ou armazém geral",
    },
    {
        "code": "2906",
        "message": "Retorno de mercadoria remetida p/ depósito fechado ou armazém geral",
    },
    {
        "code": "2907",
        "message": "Retorno simbólico de mercadoria remetida p/ depósito fechado ou armazém geral",
    },
    {
        "code": "2908",
        "message": "Entrada de bem por conta de contrato de comodato",
    },
    {
        "code": "2909",
        "message": "Retorno de bem remetido por conta de contrato de comodato",
    },
    {
        "code": "2910",
        "message": "Entrada de bonificação, doação ou brinde",
    },
    {
        "code": "2911",
        "message": "Entrada de amostra grátis",
    },
    {
        "code": "2912",
        "message": "Entrada de mercadoria ou bem recebido p/ demonstração",
    },
    {
        "code": "2913",
        "message": "Retorno de mercadoria ou bem remetido p/ demonstração",
    },
    {
        "code": "2914",
        "message": "Retorno de mercadoria ou bem remetido p/ exposição ou feira",
    },
    {
        "code": "2915",
        "message": "Entrada de mercadoria ou bem recebido p/ conserto ou reparo",
    },
    {
        "code": "2916",
        "message": "Retorno de mercadoria ou bem remetido p/ conserto ou reparo",
    },
    {
        "code": "2917",
        "message": "Entrada de mercadoria recebida em consignação mercantil ou industrial",
    },
    {
        "code": "2918",
        "message": "Devolução de mercadoria remetida em consignação mercantil ou industrial",
        "isDevolution": true
    },
    {
        "code": "2919",
        "message": "Devolução simbólica de mercadoria vendida ou utilizada em processo industrial, remetida anteriormente em consignação mercantil ou industrial",
        "isDevolution": true
    },
    {
        "code": "2920",
        "message": "Entrada de vasilhame ou sacaria",
    },
    {
        "code": "2921",
        "message": "Retorno de vasilhame ou sacaria",
    },
    {
        "code": "2922",
        "message": "Lançamento efetuado a título de simples faturamento decorrente de compra p/ recebimento futuro",
    },
    {
        "code": "2923",
        "message": "Entrada de mercadoria recebida do vendedor remetente, em venda à ordem",
    },
    {
        "code": "2924",
        "message": "Entrada p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "2925",
        "message": "Retorno de mercadoria remetida p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "2931",
        "message": "Lançamento efetuado pelo tomador do serviço de transporte, quando a responsabilidade de retenção do imposto for atribuída ao remetente ou alienante da mercadoria, pelo serviço de transporte realizado por transportador autônomo ou por transportador não-inscrito na UF onde se tenha iniciado o serviço  ",
    },
    {
        "code": "2932",
        "message": "Aquisição de serviço de transporte iniciado em UF diversa daquela onde esteja inscrito o prestador ",
    },
    {
        "code": "2933",
        "message": "Aquisição de serviço tributado pelo Imposto Sobre Serviços de Qualquer Natureza",
    },
    {
        "code": "2934",
        "message": "Entrada simbólica de mercadoria recebida p/ depósito fechado ou armazém geral",
    },
    {
        "code": "2949",
        "message": "Outra entrada de mercadoria ou prestação de serviço não especificado",
    },
    {
        "code": "3101",
        "message": "Compra p/ industrialização ou produção rural",
    },
    {
        "code": "3102",
        "message": "Compra p/ comercialização",
    },
    {
        "code": "3126",
        "message": "Compra p/ utilização na prestação de serviço sujeita ao ICMS",
    },
    {
        "code": "3127",
        "message": "Compra p/ industrialização sob o regime de drawback ",
    },
    {
        "code": "3128",
        "message": "Compra p/ utilização na prestação de serviço sujeita ao ISSQN",
    },
    {
        "code": "3129",
        "message": "Compra para industrialização sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)",
    },
    {
        "code": "3201",
        "message": "Devolução de venda de produção do estabelecimento",
        "isDevolution": true
    },
    {
        "code": "3202",
        "message": "Devolução de venda de mercadoria adquirida ou recebida de terceiros",
        "isDevolution": true
    },
    {
        "code": "3205",
        "message": "Anulação de valor relativo à prestação de serviço de comunicação",
    },
    {
        "code": "3206",
        "message": "Anulação de valor relativo à prestação de serviço de transporte",
    },
    {
        "code": "3207",
        "message": "Anulação de valor relativo à venda de energia elétrica",
    },
    {
        "code": "3211",
        "message": "Devolução de venda de produção do estabelecimento sob o regime de drawback ",
        "isDevolution": true
    },
    {
        "code": "3212",
        "message": "Devolução de venda no mercado externo de mercadoria industrializada sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)",
        "isDevolution": true
    },
    {
        "code": "3251",
        "message": "Compra de energia elétrica p/ distribuição ou comercialização",
    },
    {
        "code": "3301",
        "message": "Aquisição de serviço de comunicação p/ execução de serviço da mesma natureza",
    },
    {
        "code": "3351",
        "message": "Aquisição de serviço de transporte p/ execução de serviço da mesma natureza",
    },
    {
        "code": "3352",
        "message": "Aquisição de serviço de transporte por estabelecimento industrial",
    },
    {
        "code": "3353",
        "message": "Aquisição de serviço de transporte por estabelecimento comercial",
    },
    {
        "code": "3354",
        "message": "Aquisição de serviço de transporte por estabelecimento de prestador de serviço de comunicação",
    },
    {
        "code": "3355",
        "message": "Aquisição de serviço de transporte por estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "3356",
        "message": "Aquisição de serviço de transporte por estabelecimento de produtor rural",
    },
    {
        "code": "3503",
        "message": "Devolução de mercadoria exportada que tenha sido recebida com fim específico de exportação",
        "isDevolution": true
    },
    {
        "code": "3551",
        "message": "Compra de bem p/ o ativo imobilizado",
    },
    {
        "code": "3553",
        "message": "Devolução de venda de bem do ativo imobilizado",
        "isDevolution": true
    },
    {
        "code": "3556",
        "message": "Compra de material p/ uso ou consumo",
    },
    {
        "code": "3651",
        "message": "Compra de combustível ou lubrificante p/ industrialização subseqüente",
    },
    {
        "code": "3652",
        "message": "Compra de combustível ou lubrificante p/ comercialização",
    },
    {
        "code": "3653",
        "message": "Compra de combustível ou lubrificante por consumidor ou usuário final",
    },
    {
        "code": "3930",
        "message": "Lançamento efetuado a título de entrada de bem sob amparo de regime especial aduaneiro de admissão temporária",
    },
    {
        "code": "3949",
        "message": "Outra entrada de mercadoria ou prestação de serviço não especificado",
    },
    {
        "code": "5101",
        "message": "Venda de produção do estabelecimento",
    },
    {
        "code": "5102",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros",
    },
    {
        "code": "5103",
        "message": "Venda de produção do estabelecimento efetuada fora do estabelecimento",
    },
    {
        "code": "5104",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento",
    },
    {
        "code": "5105",
        "message": "Venda de produção do estabelecimento que não deva por ele transitar",
    },
    {
        "code": "5106",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar ",
    },
    {
        "code": "5109",
        "message": "Venda de produção do estabelecimento destinada à ZFM ou ALC",
    },
    {
        "code": "5110",
        "message": "Venda de mercadoria, adquirida ou recebida de terceiros, destinada à ZFM ou ALC",
    },
    {
        "code": "5111",
        "message": "Venda de produção do estabelecimento remetida anteriormente em consignação industrial",
    },
    {
        "code": "5112",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação industrial",
    },
    {
        "code": "5113",
        "message": "Venda de produção do estabelecimento remetida anteriormente em consignação mercantil",
    },
    {
        "code": "5114",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação mercantil",
    },
    {
        "code": "5115",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil",
    },
    {
        "code": "5116",
        "message": "Venda de produção do estabelecimento originada de encomenda p/ entrega futura",
    },
    {
        "code": "5117",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, originada de encomenda p/ entrega futura",
    },
    {
        "code": "5118",
        "message": "Venda de produção do estabelecimento entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem",
    },
    {
        "code": "5119",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem",
    },
    {
        "code": "5120",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário pelo vendedor remetente, em venda à ordem",
    },
    {
        "code": "5122",
        "message": "Venda de produção do estabelecimento remetida p/ industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "5123",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros remetida p/ industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "5124",
        "message": "Industrialização efetuada p/ outra empresa",
    },
    {
        "code": "5125",
        "message": "Industrialização efetuada p/ outra empresa quando a mercadoria recebida p/ utilização no processo de industrialização não transitar pelo estabelecimento adquirente da mercadoria",
    },
    {
        "code": "5129",
        "message": "Venda de insumo importado e de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)",
    },
    {
        "code": "5151",
        "message": "",
    },
    {
        "code": "5152",
        "message": "Transferência de mercadoria adquirida ou recebida de terceiros",
    },
    {
        "code": "5153",
        "message": "Transferência de energia elétrica",
    },
    {
        "code": "5155",
        "message": "Transferência de produção do estabelecimento, que não deva por ele transitar",
    },
    {
        "code": "5156",
        "message": "Transferência de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar",
    },
    {
        "code": "5201",
        "message": "Devolução de compra p/ industrialização ou produção rural",
        "isDevolution": true
    },
    {
        "code": "5202",
        "message": "Devolução de compra p/ comercialização",
        "isDevolution": true
    },
    {
        "code": "5205",
        "message": "Anulação de valor relativo a aquisição de serviço de comunicação",
    },
    {
        "code": "5206",
        "message": "Anulação de valor relativo a aquisição de serviço de transporte",
    },
    {
        "code": "5207",
        "message": "Anulação de valor relativo à compra de energia elétrica",
    },
    {
        "code": "5208",
        "message": "Devolução de mercadoria recebida em transferência p/ industrialização ou produção rural ",
        "isDevolution": true
    },
    {
        "code": "5209",
        "message": "Devolução de mercadoria recebida em transferência p/ comercialização",
        "isDevolution": true
    },
    {
        "code": "5210",
        "message": "Devolução de compra p/ utilização na prestação de serviço",
        "isDevolution": true
    },
    {
        "code": "5251",
        "message": "Venda de energia elétrica p/ distribuição ou comercialização",
    },
    {
        "code": "5252",
        "message": "Venda de energia elétrica p/ estabelecimento industrial",
    },
    {
        "code": "5253",
        "message": "Venda de energia elétrica p/ estabelecimento comercial",
    },
    {
        "code": "5254",
        "message": "Venda de energia elétrica p/ estabelecimento prestador de serviço de transporte",
    },
    {
        "code": "5255",
        "message": "Venda de energia elétrica p/ estabelecimento prestador de serviço de comunicação",
    },
    {
        "code": "5256",
        "message": "Venda de energia elétrica p/ estabelecimento de produtor rural",
    },
    {
        "code": "5257",
        "message": "Venda de energia elétrica p/ consumo por demanda contratada",
    },
    {
        "code": "5258",
        "message": "Venda de energia elétrica a não contribuinte",
    },
    {
        "code": "5301",
        "message": "Prestação de serviço de comunicação p/ execução de serviço da mesma natureza",
    },
    {
        "code": "5302",
        "message": "Prestação de serviço de comunicação a estabelecimento industrial",
    },
    {
        "code": "5303",
        "message": "Prestação de serviço de comunicação a estabelecimento comercial",
    },
    {
        "code": "5304",
        "message": "Prestação de serviço de comunicação a estabelecimento de prestador de serviço de transporte",
    },
    {
        "code": "5305",
        "message": "Prestação de serviço de comunicação a estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "5306",
        "message": "Prestação de serviço de comunicação a estabelecimento de produtor rural",
    },
    {
        "code": "5307",
        "message": "Prestação de serviço de comunicação a não contribuinte",
    },
    {
        "code": "5351",
        "message": "Prestação de serviço de transporte p/ execução de serviço da mesma natureza",
    },
    {
        "code": "5352",
        "message": "Prestação de serviço de transporte a estabelecimento industrial",
    },
    {
        "code": "5353",
        "message": "Prestação de serviço de transporte a estabelecimento comercial",
    },
    {
        "code": "5354",
        "message": "Prestação de serviço de transporte a estabelecimento de prestador de serviço de comunicação",
    },
    {
        "code": "5355",
        "message": "Prestação de serviço de transporte a estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "5356",
        "message": "Prestação de serviço de transporte a estabelecimento de produtor rural",
    },
    {
        "code": "5357",
        "message": "Prestação de serviço de transporte a não contribuinte",
    },
    {
        "code": "5359",
        "message": "Prestação de serviço de transporte a contribuinte ou a não-contribuinte, quando a mercadoria transportada esteja dispensada de emissão de Nota Fiscal  ",
    },
    {
        "code": "5360",
        "message": "Prestação de serviço de transporte a contribuinte-substituto em relação ao serviço de transporte",
    },
    {
        "code": "5401",
        "message": "Venda de produção do estabelecimento quando o produto esteja sujeito a ST",
    },
    {
        "code": "5402",
        "message": "Venda de produção do estabelecimento de produto sujeito a ST, em operação entre contribuintes substitutos do mesmo produto",
    },
    {
        "code": "5403",
        "message": "Venda de mercadoria, adquirida ou recebida de terceiros, sujeita a ST, na condição de contribuinte-substituto",
    },
    {
        "code": "5405",
        "message": "Venda de mercadoria, adquirida ou recebida de terceiros, sujeita a ST, na condição de contribuinte-substituído",
    },
    {
        "code": "5408",
        "message": "Transferência de produção do estabelecimento quando o produto sujeito a ST",
    },
    {
        "code": "5409",
        "message": "Transferência de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita a ST",
    },
    {
        "code": "5410",
        "message": "Devolução de compra p/ industrialização de mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "5411",
        "message": "Devolução de compra p/ comercialização em operação com mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "5412",
        "message": "Devolução de bem do ativo imobilizado, em operação com mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "5413",
        "message": "Devolução de mercadoria destinada ao uso ou consumo, em operação com mercadoria sujeita a ST.",
        "isDevolution": true
    },
    {
        "code": "5414",
        "message": "Remessa de produção do estabelecimento p/ venda fora do estabelecimento, quando o produto sujeito a ST",
    },
    {
        "code": "5415",
        "message": "Remessa de mercadoria adquirida ou recebida de terceiros p/ venda fora do estabelecimento, em operação com mercadoria sujeita a ST",
    },
    {
        "code": "5451",
        "message": "Remessa de animal e de insumo p/ estabelecimento produtor",
    },
    {
        "code": "5501",
        "message": "Remessa de produção do estabelecimento, com fim específico de exportação",
    },
    {
        "code": "5502",
        "message": "Remessa de mercadoria adquirida ou recebida de terceiros, com fim específico de exportação",
    },
    {
        "code": "5503",
        "message": "Devolução de mercadoria recebida com fim específico de exportação",
        "isDevolution": true
    },
    {
        "code": "5504",
        "message": "Remessa de mercadoria p/ formação de lote de exportação, de produto industrializado ou produzido pelo próprio estabelecimento.",
    },
    {
        "code": "5505",
        "message": "Remessa de mercadoria, adquirida ou recebida de terceiros, p/ formação de lote de exportação.",
    },
    {
        "code": "5551",
        "message": "Venda de bem do ativo imobilizado",
    },
    {
        "code": "5552",
        "message": "Transferência de bem do ativo imobilizado",
    },
    {
        "code": "5553",
        "message": "Devolução de compra de bem p/ o ativo imobilizado",
        "isDevolution": true
    },
    {
        "code": "5554",
        "message": "Remessa de bem do ativo imobilizado p/ uso fora do estabelecimento",
    },
    {
        "code": "5555",
        "message": "Devolução de bem do ativo imobilizado de terceiro, recebido p/ uso no estabelecimento",
        "isDevolution": true
    },
    {
        "code": "5556",
        "message": "Devolução de compra de material de uso ou consumo",
        "isDevolution": true
    },
    {
        "code": "5557",
        "message": "Transferência de material de uso ou consumo",
    },
    {
        "code": "5601",
        "message": "Transferência de crédito de ICMS acumulado",
    },
    {
        "code": "5602",
        "message": "Transferência de saldo credor do ICMS, p/ outro estabelecimento da mesma empresa, destinado à compensação de saldo devedor do ICMS",
    },
    {
        "code": "5603",
        "message": "Ressarcimento de ICMS retido por substituição tributária",
    },
    {
        "code": "5605",
        "message": "Transferência de saldo devedor do ICMS de outro estabelecimento da mesma empresa  ",
    },
    {
        "code": "5606",
        "message": "Utilização de saldo credor do ICMS p/ extinção por compensação de débitos fiscais",
    },
    {
        "code": "5651",
        "message": "Venda de combustível ou lubrificante de produção do estabelecimento destinados à industrialização subseqüente",
    },
    {
        "code": "5652",
        "message": "Venda de combustível ou lubrificante, de produção do estabelecimento, destinados à comercialização",
    },
    {
        "code": "5653",
        "message": "Venda de combustível ou lubrificante, de produção do estabelecimento, destinados a consumidor ou usuário final",
    },
    {
        "code": "5654",
        "message": "Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados à industrialização subseqüente",
    },
    {
        "code": "5655",
        "message": "Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados à comercialização",
    },
    {
        "code": "5656",
        "message": "Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados a consumidor ou usuário final",
    },
    {
        "code": "5657",
        "message": "Remessa de combustível ou lubrificante, adquiridos ou recebidos de terceiros, p/ venda fora do estabelecimento",
    },
    {
        "code": "5658",
        "message": "Transferência de combustível ou lubrificante de produção do estabelecimento",
    },
    {
        "code": "5659",
        "message": "Transferência de combustível ou lubrificante adquiridos ou recebidos de terceiros",
    },
    {
        "code": "5660",
        "message": "Devolução de compra de combustível ou lubrificante adquiridos p/ industrialização subseqüente",
        "isDevolution": true
    },
    {
        "code": "5661",
        "message": "Devolução de compra de combustível ou lubrificante adquiridos p/ comercialização",
        "isDevolution": true
    },
    {
        "code": "5662",
        "message": "Devolução de compra de combustível ou lubrificante adquiridos por consumidor ou usuário final",
        "isDevolution": true
    },
    {
        "code": "5663",
        "message": "Remessa p/ armazenagem de combustível ou lubrificante",
    },
    {
        "code": "5664",
        "message": "Retorno de combustível ou lubrificante recebidos p/ armazenagem",
    },
    {
        "code": "5665",
        "message": "Retorno simbólico de combustível ou lubrificante recebidos p/ armazenagem",
    },
    {
        "code": "5666",
        "message": "Remessa, por conta e ordem de terceiros, de combustível ou lubrificante recebidos p/ armazenagem",
    },
    {
        "code": "5667",
        "message": "Venda de combustível ou lubrificante a consumidor ou usuário final estabelecido em outra UF",
    },
    {
        "code": "5901",
        "message": "Remessa p/ industrialização por encomenda",
    },
    {
        "code": "5902",
        "message": "Retorno de mercadoria utilizada na industrialização por encomenda",
    },
    {
        "code": "5903",
        "message": "Retorno de mercadoria recebida p/ industrialização e não aplicada no referido processo",
    },
    {
        "code": "5904",
        "message": "Remessa p/ venda fora do estabelecimento",
    },
    {
        "code": "5905",
        "message": "Remessa p/ depósito fechado ou armazém geral",
    },
    {
        "code": "5906",
        "message": "Retorno de mercadoria depositada em depósito fechado ou armazém geral",
    },
    {
        "code": "5907",
        "message": "Retorno simbólico de mercadoria depositada em depósito fechado ou armazém geral",
    },
    {
        "code": "5908",
        "message": "Remessa de bem por conta de contrato de comodato",
    },
    {
        "code": "5909",
        "message": "Retorno de bem recebido por conta de contrato de comodato",
    },
    {
        "code": "5910",
        "message": "Remessa em bonificação, doação ou brinde",
    },
    {
        "code": "5911",
        "message": "Remessa de amostra grátis",
    },
    {
        "code": "5912",
        "message": "Remessa de mercadoria ou bem p/ demonstração",
    },
    {
        "code": "5913",
        "message": "Retorno de mercadoria ou bem recebido p/ demonstração",
    },
    {
        "code": "5914",
        "message": "Remessa de mercadoria ou bem p/ exposição ou feira",
    },
    {
        "code": "5915",
        "message": "Remessa de mercadoria ou bem p/ conserto ou reparo",
    },
    {
        "code": "5916",
        "message": "Retorno de mercadoria ou bem recebido p/ conserto ou reparo",
    },
    {
        "code": "5917",
        "message": "Remessa de mercadoria em consignação mercantil ou industrial",
    },
    {
        "code": "5918",
        "message": "Devolução de mercadoria recebida em consignação mercantil ou industrial",
        "isDevolution": true
    },
    {
        "code": "5919",
        "message": "Devolução simbólica de mercadoria vendida ou utilizada em processo industrial, recebida anteriormente em consignação mercantil ou industrial",
        "isDevolution": true
    },
    {
        "code": "5920",
        "message": "Remessa de vasilhame ou sacaria",
    },
    {
        "code": "5921",
        "message": "Devolução de vasilhame ou sacaria",
        "isDevolution": true
    },
    {
        "code": "5922",
        "message": "Lançamento efetuado a título de simples faturamento decorrente de venda p/ entrega futura",
    },
    {
        "code": "5923",
        "message": "Remessa de mercadoria por conta e ordem de terceiros, em venda à ordem ou em operações com armazém geral ou depósito fechado.",
    },
    {
        "code": "5924",
        "message": "Remessa p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "5925",
        "message": "Retorno de mercadoria recebida p/ industrialização por conta e ordem do adquirente da mercadoria, quando aquela não transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "5926",
        "message": "Lançamento efetuado a título de reclassificação de mercadoria decorrente de formação de kit ou de sua desagregação",
    },
    {
        "code": "5927",
        "message": "Lançamento efetuado a título de baixa de estoque decorrente de perda, roubo ou deterioração",
    },
    {
        "code": "5928",
        "message": "Lançamento efetuado a título de baixa de estoque decorrente do encerramento da atividade da empresa",
    },
    {
        "code": "5929",
        "message": "Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal - ECF",
    },
    {
        "code": "5931",
        "message": "Lançamento efetuado em decorrência da responsabilidade de retenção do imposto por substituição tributária, atribuída ao remetente ou alienante da mercadoria, pelo serviço de transporte realizado por transportador autônomo ou por transportador não inscrito na UF onde iniciado o serviço",
    },
    {
        "code": "5932",
        "message": "Prestação de serviço de transporte iniciada em UF diversa daquela onde inscrito o prestador",
    },
    {
        "code": "5933",
        "message": "Prestação de serviço tributado pelo Imposto Sobre Serviços de Qualquer Natureza",
    },
    {
        "code": "5934",
        "message": "Remessa simbólica de mercadoria depositada em armazém geral ou depósito fechado.",
    },
    {
        "code": "5949",
        "message": "Outra saída de mercadoria ou prestação de serviço não especificado",
    },
    {
        "code": "6101",
        "message": "Venda de produção do estabelecimento",
    },
    {
        "code": "6102",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros",
    },
    {
        "code": "6103",
        "message": "Venda de produção do estabelecimento, efetuada fora do estabelecimento",
    },
    {
        "code": "6104",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento",
    },
    {
        "code": "6105",
        "message": "Venda de produção do estabelecimento que não deva por ele transitar",
    },
    {
        "code": "6106",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar",
    },
    {
        "code": "6107",
        "message": "Venda de produção do estabelecimento, destinada a não contribuinte",
    },
    {
        "code": "6108",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, destinada a não contribuinte",
    },
    {
        "code": "6109",
        "message": "Venda de produção do estabelecimento destinada à ZFM ou ALC",
    },
    {
        "code": "6110",
        "message": "Venda de mercadoria, adquirida ou recebida de terceiros, destinada à ZFM ou ALC",
    },
    {
        "code": "6111",
        "message": "Venda de produção do estabelecimento remetida anteriormente em consignação industrial",
    },
    {
        "code": "6112",
        "message": "Venda de mercadoria adquirida ou recebida de Terceiros remetida anteriormente em consignação industrial",
    },
    {
        "code": "6113",
        "message": "Venda de produção do estabelecimento remetida anteriormente em consignação mercantil",
    },
    {
        "code": "6114",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação mercantil",
    },
    {
        "code": "6115",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil",
    },
    {
        "code": "6116",
        "message": "Venda de produção do estabelecimento originada de encomenda p/ entrega futura",
    },
    {
        "code": "6117",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, originada de encomenda p/ entrega futura",
    },
    {
        "code": "6118",
        "message": "Venda de produção do estabelecimento entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem",
    },
    {
        "code": "6119",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem",
    },
    {
        "code": "6120",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário pelo vendedor remetente, em venda à ordem",
    },
    {
        "code": "6122",
        "message": "Venda de produção do estabelecimento remetida p/ industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "6123",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros remetida p/ industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "6124",
        "message": "Industrialização efetuada p/ outra empresa",
    },
    {
        "code": "6125",
        "message": "Industrialização efetuada p/ outra empresa quando a mercadoria recebida p/ utilização no processo de industrialização não transitar pelo estabelecimento adquirente da mercadoria",
    },
    {
        "code": "6129",
        "message": "Venda de insumo importado e de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)",
    },
    {
        "code": "6151",
        "message": "Transferência de produção do estabelecimento",
    },
    {
        "code": "6152",
        "message": "Transferência de mercadoria adquirida ou recebida de terceiros",
    },
    {
        "code": "6153",
        "message": "Transferência de energia elétrica",
    },
    {
        "code": "6155",
        "message": "Transferência de produção do estabelecimento, que não deva por ele transitar",
    },
    {
        "code": "6156",
        "message": "Transferência de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar",
    },
    {
        "code": "6201",
        "message": "Devolução de compra p/ industrialização ou produção rural",
        "isDevolution": true
    },
    {
        "code": "6202",
        "message": "Devolução de compra p/ comercialização",
        "isDevolution": true
    },
    {
        "code": "6205",
        "message": "Anulação de valor relativo a aquisição de serviço de comunicação",
    },
    {
        "code": "6206",
        "message": "Anulação de valor relativo a aquisição de serviço de transporte",
    },
    {
        "code": "6207",
        "message": "Anulação de valor relativo à compra de energia elétrica",
    },
    {
        "code": "6208",
        "message": "Devolução de mercadoria recebida em transferência p/ industrialização ou produção rural ",
        "isDevolution": true
    },
    {
        "code": "6209",
        "message": "Devolução de mercadoria recebida em transferência p/ comercialização",
        "isDevolution": true
    },
    {
        "code": "6210",
        "message": "Devolução de compra p/ utilização na prestação de serviço",
        "isDevolution": true
    },
    {
        "code": "6251",
        "message": "Venda de energia elétrica p/ distribuição ou comercialização",
    },
    {
        "code": "6252",
        "message": "Venda de energia elétrica p/ estabelecimento industrial",
    },
    {
        "code": "6253",
        "message": "Venda de energia elétrica p/ estabelecimento comercial",
    },
    {
        "code": "6254",
        "message": "Venda de energia elétrica p/ estabelecimento prestador de serviço de transporte",
    },
    {
        "code": "6255",
        "message": "Venda de energia elétrica p/ estabelecimento prestador de serviço de comunicação",
    },
    {
        "code": "6256",
        "message": "Venda de energia elétrica p/ estabelecimento de produtor rural",
    },
    {
        "code": "6257",
        "message": "Venda de energia elétrica p/ consumo por demanda contratada",
    },
    {
        "code": "6258",
        "message": "Venda de energia elétrica a não contribuinte",
    },
    {
        "code": "6301",
        "message": "Prestação de serviço de comunicação p/ execução de serviço da mesma natureza",
    },
    {
        "code": "6302",
        "message": "Prestação de serviço de comunicação a estabelecimento industrial",
    },
    {
        "code": "6303",
        "message": "Prestação de serviço de comunicação a estabelecimento comercial",
    },
    {
        "code": "6304",
        "message": "Prestação de serviço de comunicação a estabelecimento de prestador de serviço de transporte",
    },
    {
        "code": "6305",
        "message": "Prestação de serviço de comunicação a estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "6306",
        "message": "Prestação de serviço de comunicação a estabelecimento de produtor rural",
    },
    {
        "code": "6307",
        "message": "Prestação de serviço de comunicação a não contribuinte",
    },
    {
        "code": "6351",
        "message": "Prestação de serviço de transporte p/ execução de serviço da mesma natureza",
    },
    {
        "code": "6352",
        "message": "Prestação de serviço de transporte a estabelecimento industrial",
    },
    {
        "code": "6353",
        "message": "Prestação de serviço de transporte a estabelecimento comercial",
    },
    {
        "code": "6354",
        "message": "Prestação de serviço de transporte a estabelecimento de prestador de serviço de comunicação",
    },
    {
        "code": "6355",
        "message": "Prestação de serviço de transporte a estabelecimento de geradora ou de distribuidora de energia elétrica",
    },
    {
        "code": "6356",
        "message": "Prestação de serviço de transporte a estabelecimento de produtor rural",
    },
    {
        "code": "6357",
        "message": "Prestação de serviço de transporte a não contribuinte",
    },
    {
        "code": "6359",
        "message": "Prestação de serviço de transporte a contribuinte ou a não-contribuinte, quando a mercadoria transportada esteja dispensada de emissão de Nota Fiscal  ",
    },
    {
        "code": "6360",
        "message": "Prestação de serviço de transporte a contribuinte substituto em relação ao serviço de transporte  ",
    },
    {
        "code": "6401",
        "message": "Venda de produção do estabelecimento quando o produto sujeito a ST",
    },
    {
        "code": "6402",
        "message": "Venda de produção do estabelecimento de produto sujeito a ST, em operação entre contribuintes substitutos do mesmo produto",
    },
    {
        "code": "6403",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita a ST, na condição de contribuinte substituto",
    },
    {
        "code": "6404",
        "message": "Venda de mercadoria sujeita a ST, cujo imposto já tenha sido retido anteriormente",
    },
    {
        "code": "6408",
        "message": "Transferência de produção do estabelecimento quando o produto sujeito a ST",
    },
    {
        "code": "6409",
        "message": "Transferência de mercadoria adquirida ou recebida de terceiros, sujeita a ST",
    },
    {
        "code": "6410",
        "message": "Devolução de compra p/ industrialização ou ptrodução rural quando a mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "6411",
        "message": "Devolução de compra p/ comercialização em operação com mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "6412",
        "message": "Devolução de bem do ativo imobilizado, em operação com mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "6413",
        "message": "Devolução de mercadoria destinada ao uso ou consumo, em operação com mercadoria sujeita a ST",
        "isDevolution": true
    },
    {
        "code": "6414",
        "message": "Remessa de produção do estabelecimento p/ venda fora do estabelecimento, quando o produto sujeito a ST",
    },
    {
        "code": "6415",
        "message": "Remessa de mercadoria adquirida ou recebida de terceiros p/ venda fora do estabelecimento, quando a referida ração com mercadoria sujeita a ST",
    },
    {
        "code": "6501",
        "message": "Remessa de produção do estabelecimento, com fim específico de exportação",
    },
    {
        "code": "6502",
        "message": "Remessa de mercadoria adquirida ou recebida de terceiros, com fim específico de exportação",
    },
    {
        "code": "6503",
        "message": "Devolução de mercadoria recebida com fim específico de exportação",
        "isDevolution": true
    },
    {
        "code": "6504",
        "message": "Remessa de mercadoria p/ formação de lote de exportação, de produto industrializado ou produzido pelo próprio estabelecimento.",
    },
    {
        "code": "6505",
        "message": "Remessa de mercadoria, adquirida ou recebida de terceiros, p/ formação de lote de exportação.",
    },
    {
        "code": "6551",
        "message": "Venda de bem do ativo imobilizado",
    },
    {
        "code": "6552",
        "message": "Transferência de bem do ativo imobilizado",
    },
    {
        "code": "6553",
        "message": "Devolução de compra de bem p/ o ativo imobilizado",
        "isDevolution": true
    },
    {
        "code": "6554",
        "message": "Remessa de bem do ativo imobilizado p/ uso fora do estabelecimento",
    },
    {
        "code": "6555",
        "message": "Devolução de bem do ativo imobilizado de terceiro, recebido p/ uso no estabelecimento",
        "isDevolution": true
    },
    {
        "code": "6556",
        "message": "Devolução de compra de material de uso ou consumo",
        "isDevolution": true
    },
    {
        "code": "6557",
        "message": "Transferência de material de uso ou consumo",
    },
    {
        "code": "6603",
        "message": "Ressarcimento de ICMS retido por substituição tributária",
    },
    {
        "code": "6651",
        "message": "Venda de combustível ou lubrificante, de produção do estabelecimento, destinados à industrialização subseqüente",
    },
    {
        "code": "6652",
        "message": "Venda de combustível ou lubrificante, de produção do estabelecimento, destinados à comercialização",
    },
    {
        "code": "6653",
        "message": "Venda de combustível ou lubrificante, de produção do estabelecimento, destinados a consumidor ou usuário final ",
    },
    {
        "code": "6654",
        "message": "Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados à industrialização subseqüente ",
    },
    {
        "code": "6655",
        "message": "Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados à comercialização",
    },
    {
        "code": "6656",
        "message": "Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados a consumidor ou usuário final",
    },
    {
        "code": "6657",
        "message": "Remessa de combustível ou lubrificante, adquiridos ou recebidos de terceiros, p/ venda fora do estabelecimento",
    },
    {
        "code": "6658",
        "message": "Transferência de combustível ou lubrificante de produção do estabelecimento",
    },
    {
        "code": "6659",
        "message": "Transferência de combustível ou lubrificante adquiridos ou recebidos de terceiros",
    },
    {
        "code": "6660",
        "message": "Devolução de compra de combustível ou lubrificante adquiridos p/ industrialização subseqüente",
        "isDevolution": true
    },
    {
        "code": "6661",
        "message": "Devolução de compra de combustível ou lubrificante adquiridos p/ comercialização",
        "isDevolution": true
    },
    {
        "code": "6662",
        "message": "Devolução de compra de combustível ou lubrificante adquiridos por consumidor ou usuário final",
        "isDevolution": true
    },
    {
        "code": "6663",
        "message": "Remessa p/ armazenagem de combustível ou lubrificante",
    },
    {
        "code": "6664",
        "message": "Retorno de combustível ou lubrificante recebidos p/ armazenagem",
    },
    {
        "code": "6665",
        "message": "Retorno simbólico de combustível ou lubrificante recebidos p/ armazenagem",
    },
    {
        "code": "6666",
        "message": "Remessa, por conta e ordem de terceiros, de combustível ou lubrificante recebidos p/ armazenagem",
    },
    {
        "code": "6667",
        "message": "Venda de combustível ou lubrificante a consumidor ou usuário final estabelecido em outra UF diferente da que ocorrer o consumo",
    },
    {
        "code": "6901",
        "message": "Remessa p/ industrialização por encomenda",
    },
    {
        "code": "6902",
        "message": "Retorno de mercadoria utilizada na industrialização por encomenda",
    },
    {
        "code": "6903",
        "message": "Retorno de mercadoria recebida p/ industrialização e não aplicada no referido processo",
    },
    {
        "code": "6904",
        "message": "Remessa p/ venda fora do estabelecimento",
    },
    {
        "code": "6905",
        "message": "Remessa p/ depósito fechado ou armazém geral",
    },
    {
        "code": "6906",
        "message": "Retorno de mercadoria depositada em depósito fechado ou armazém geral",
    },
    {
        "code": "6907",
        "message": "Retorno simbólico de mercadoria depositada em depósito fechado ou armazém geral",
    },
    {
        "code": "6908",
        "message": "Remessa de bem por conta de contrato de comodato",
    },
    {
        "code": "6909",
        "message": "Retorno de bem recebido por conta de contrato de comodato",
    },
    {
        "code": "6910",
        "message": "Remessa em bonificação, doação ou brinde",
    },
    {
        "code": "6911",
        "message": "Remessa de amostra grátis",
    },
    {
        "code": "6912",
        "message": "Remessa de mercadoria ou bem p/ demonstração",
    },
    {
        "code": "6913",
        "message": "Retorno de mercadoria ou bem recebido p/ demonstração",
    },
    {
        "code": "6914",
        "message": "Remessa de mercadoria ou bem p/ exposição ou feira",
    },
    {
        "code": "6915",
        "message": "Remessa de mercadoria ou bem p/ conserto ou reparo",
    },
    {
        "code": "6916",
        "message": "Retorno de mercadoria ou bem recebido p/ conserto ou reparo",
    },
    {
        "code": "6917",
        "message": "Remessa de mercadoria em consignação mercantil ou industrial",
    },
    {
        "code": "6918",
        "message": "Devolução de mercadoria recebida em consignação mercantil ou industrial",
        "isDevolution": true
    },
    {
        "code": "6919",
        "message": "Devolução simbólica de mercadoria vendida ou utilizada em processo industrial, recebida anteriormente em consignação mercantil ou industrial",
        "isDevolution": true
    },
    {
        "code": "6920",
        "message": "Remessa de vasilhame ou sacaria",
    },
    {
        "code": "6921",
        "message": "Devolução de vasilhame ou sacaria",
        "isDevolution": true
    },
    {
        "code": "6922",
        "message": "Lançamento efetuado a título de simples faturamento decorrente de venda p/ entrega futura",
    },
    {
        "code": "6923",
        "message": "Remessa de mercadoria por conta e ordem de terceiros, em venda à ordem ou em operações com armazém geral ou depósito fechado",
    },
    {
        "code": "6924",
        "message": "Remessa p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "6925",
        "message": "Retorno de mercadoria recebida p/ industrialização por conta e ordem do adquirente da mercadoria, quando aquela não transitar pelo estabelecimento do adquirente",
    },
    {
        "code": "6929",
        "message": "Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal - ECF",
    },
    {
        "code": "6931",
        "message": "Lançamento efetuado em decorrência da responsabilidade de retenção do imposto por substituição tributária, atribuída ao remetente ou alienante da mercadoria, pelo serviço de transporte realizado por transportador autônomo ou por transportador não inscrito na UF onde iniciado o serviço",
    },
    {
        "code": "6932",
        "message": "Prestação de serviço de transporte iniciada em UF diversa daquela onde inscrito o prestador",
    },
    {
        "code": "6933",
        "message": "Prestação de serviço tributado pelo Imposto Sobre Serviços de Qualquer Natureza ",
    },
    {
        "code": "6934",
        "message": "Remessa simbólica de mercadoria depositada em armazém geral ou depósito fechado",
    },
    {
        "code": "6949",
        "message": "Outra saída de mercadoria ou prestação de serviço não especificado",
    },
    {
        "code": "7101",
        "message": "Venda de produção do estabelecimento",
        "isExportation": true
    },
    {
        "code": "7102",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros",
        "isExportation": true
    },
    {
        "code": "7105",
        "message": "Venda de produção do estabelecimento, que não deva por ele transitar",
    },
    {
        "code": "7106",
        "message": "Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar",
    },
    {
        "code": "7127",
        "message": "Venda de produção do estabelecimento sob o regime de drawback ",
        "isExportation": true
    },
    {
        "code": "7129",
        "message": "Venda de produção do estabelecimento ao mercado externo de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)",
    },
    {
        "code": "7201",
        "message": "Devolução de compra p/ industrialização ou produção rural",
        "isDevolution": true
    },
    {
        "code": "7202",
        "message": "Devolução de compra p/ comercialização",
        "isDevolution": true
    },
    {
        "code": "7205",
        "message": "Anulação de valor relativo à aquisição de serviço de comunicação",
    },
    {
        "code": "7206",
        "message": "Anulação de valor relativo a aquisição de serviço de transporte",
    },
    {
        "code": "7207",
        "message": "Anulação de valor relativo à compra de energia elétrica",
    },
    {
        "code": "7210",
        "message": "Devolução de compra p/ utilização na prestação de serviço",
        "isDevolution": true
    },
    {
        "code": "7211",
        "message": "Devolução de compras p/ industrialização sob o regime de drawback ",
        "isDevolution": true
    },
    {
        "code": "7212",
        "message": "Devolução de compras para industrialização sob o regime de Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)",
        "isDevolution": true
    },
    {
        "code": "7251",
        "message": "Venda de energia elétrica p/ o exterior",
    },
    {
        "code": "7301",
        "message": "Prestação de serviço de comunicação p/ execução de serviço da mesma natureza",
    },
    {
        "code": "7358",
        "message": "Prestação de serviço de transporte",
    },
    {
        "code": "7501",
        "message": "Exportação de mercadorias recebidas com fim específico de exportação",
    },
    {
        "code": "7551",
        "message": "Venda de bem do ativo imobilizado",
    },
    {
        "code": "7553",
        "message": "Devolução de compra de bem p/ o ativo imobilizado",
        "isDevolution": true
    },
    {
        "code": "7556",
        "message": "Devolução de compra de material de uso ou consumo",
        "isDevolution": true
    },
    {
        "code": "7651",
        "message": "Venda de combustível ou lubrificante de produção do estabelecimento",
    },
    {
        "code": "7654",
        "message": "Venda de combustível ou lubrificante adquiridos ou recebidos de terceiros",
    },
    {
        "code": "7667",
        "message": "Venda de combustível ou lubrificante a consumidor ou usuário final",
    },
    {
        "code": "7930",
        "message": "Lançamento efetuado a título de devolução de bem cuja entrada tenha ocorrido sob amparo de regime especial aduaneiro de admissão temporária",
        "isExportation": true
    },
    {
        "code": "7949",
        "message": "Outra saída de mercadoria ou prestação de serviço não especificado",
        "isExportation": true
    }
]